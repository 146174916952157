import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import '@ckeditor/ckeditor5-build-decoupled-document/build/translations/es';

function Editor({ onChange, editorLoaded, name, value }) {
    const editorRef = useRef();
    // const HOST = "http://localhost:8080";
    const HOST = process.env.REACT_APP_BACKEND_URL;
    // const HOST_IMG_PREVIEW = process.env.REACT_APP_IMG_URL;
    const [isEditorLoading, setEditorLoading] = useState(true);

    // Iframely Acount by info@iirfranking.com
    const IFRAME_SRC = '//cdn.iframe.ly/api/iframe';
    // const API_KEY = '04d075877f21bf429afebe'
    const API_KEY = 'b336f34880d00df8b09949';

    // useEffect(() => {
    //     // Load CKEditor dynamically
    //     const loadEditor = async () => {
    //         const { CKEditor } = await import("@ckeditor/ckeditor5-react");
    //         const { default: DecoupledEditor } = await import("@ckeditor/ckeditor5-build-decoupled-document");
    //         editorRef.current = { CKEditor, DecoupledEditor };
    //     };
    //     loadEditor();
    // }, []);

    useEffect(() => {
        // Load CKEditor dynamically
        const loadEditor = async () => {
            try {
                const { CKEditor } = await import("@ckeditor/ckeditor5-react");
                const { default: DecoupledEditor } = await import("@ckeditor/ckeditor5-build-decoupled-document");
                editorRef.current = { CKEditor, DecoupledEditor };
                setEditorLoading(false);
            } catch (error) {
                console.error("Error loading CKEditor", error);
                setEditorLoading(false);
            }
        };

        loadEditor();
    }, []);
    console.log(editorRef, 'www')
    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise(async (resolve, reject) => {
                    console.log('Promises')
                    let formData = new FormData();
                    let token = localStorage.getItem('token');
                    try {
                        const file = await loader.file;
                        formData.append("newsInsideImage", file);
                        const response = await axios.post(`${HOST}news/upload-news-inside-image`, formData, {
                            headers: {
                                // "x-auth-token": token,
                                "Authorization": `Bearer ${token}`,
                            }
                        });
                        resolve({
                            default: `https://api.coeditorx.com/s3-images/${response.data.filename.key}`
                        });
                    } catch (error) {
                        reject("Error encounteres while uploading the image!");
                    }
                });
            },
            abort: () => { }
        };
    }

    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    return (
        // <div>
        //     {/* Render the toolbar container */}
        //     <div id="toolbar-container"></div>
        //     {editorRef.current && editorLoaded ? (
        //         <>

        //             {/* Render the CKEditor instance */}
        //             <editorRef.current.CKEditor
        //                 name={name}
        //                 //just add this line
        //                 onReady={(editor) => {
        //                     console.log('Editor is ready to use!', editor);
        //                     editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
        //                 }}
        //                 editor={editorRef?.current?.DecoupledEditor}
        //                 config={{
        //                     extraPlugins: [uploadPlugin],
        //                     toolbar: {
        //                         items: [
        //                             'undo', 'redo',
        //                             '|', 'heading',
        //                             '|', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor',
        //                             '|', 'bold', 'italic', 'strikethrough', 'code',
        //                             '|', 'link', 'insertImage', 'mediaEmbed', 'insertTable', 'blockQuote', 'codeBlock',
        //                             '|', 'alignment',
        //                             '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
        //                         ],
        //                         shouldNotGroupWhenFull: true
        //                     },
        //                     // Your CKEditor configuration options
        //                     heading: {
        //                         options: [
        //                             { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        //                             { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        //                             { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
        //                             { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
        //                             { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
        //                             { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
        //                             { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
        //                             // Add more heading levels as needed
        //                         ],
        //                     },
        //                     image: {
        //                         toolbar: [
        //                             'imageStyle:alignLeft', // Add left alignment option
        //                             'imageStyle:alignCenter',
        //                             'imageStyle:alignRight',
        //                             '|',
        //                             'toggleImageCaption',
        //                             'imageTextAlternative',
        //                             '|',
        //                             'linkImage',
        //                             'resizeImage',
        //                             // 'resizeImage:50',
        //                             // 'resizeImage:75',
        //                             // 'resizeImage:original',
        //                         ],
        //                         styles: [
        //                             'alignLeft', 'alignCenter', 'alignRight' // Image alignment styles
        //                         ],
        //                         align: ['left', 'center', 'right'],
        //                         // resizeOptions: [
        //                         //   // {
        //                         //   //   name: 'resizeImage:original',
        //                         //   //   label: 'Original',
        //                         //   //   value: null
        //                         //   // },
        //                         //   // {
        //                         //   //   name: 'resizeImage:50',
        //                         //   //   label: '50%',
        //                         //   //   value: '50',
        //                         //   //   icon: 'resize-small',
        //                         //   // },
        //                         //   // {
        //                         //   //   name: 'resizeImage:75',
        //                         //   //   label: '75%',
        //                         //   //   value: '75'
        //                         //   // }
        //                         //   // You can add more resize options as needed
        //                         // ],
        //                         insert: {
        //                             // This is the default configuration, you do not need to provide
        //                             // this configuration key if the list content and order reflects your needs.
        //                             integrations: ['upload', 'url'],
        //                             type: 'auto'
        //                         }
        //                     },
        //                     simpleUpload: {
        //                         // The URL that the images are uploaded to.
        //                         uploadUrl: 'http://example.com',

        //                         // Enable the XMLHttpRequest.withCredentials property.
        //                         withCredentials: true,

        //                         // Headers sent along with the XMLHttpRequest to the upload server.
        //                         headers: {
        //                             'X-CSRF-TOKEN': 'CSRF-Token',
        //                             Authorization: 'Bearer <JSON Web Token>'
        //                         }
        //                     },
        //                     ckfinder: {
        //                         // Upload the images to the server using the CKFinder QuickUpload command
        //                         // You have to change this address to your server that has the ckfinder php connector
        //                         uploadUrl: "" //Enter your upload url
        //                     },
        //                     mediaEmbed: {
        //                         previewsInData: true,
        //                         providers: [
        //                             {
        //                                 name: "youtube",
        //                                 url: /^https?:\/\/(?:www\.)?youtube\.com\/watch\?v=(.+)/,
        //                                 html: (match) => {
        //                                     return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${match[1]}" frameborder="0" allowfullscreen></iframe>`;
        //                                 }
        //                             }
        //                             // Add more media providers as needed
        //                         ]
        //                     }
        //                 }}
        //                 data={value}
        //                 onChange={(event, editor) => {
        //                     const data = editor.getData();
        //                     onChange(data);
        //                 }}
        //             />
        //         </>
        //     ) : (
        //         <div>Editor loading</div>
        //     )}
        // </div>
        <div>
            <div id="toolbar-container"></div>
            {isEditorLoading ? (
                <div>Loading CKEditor...</div>
            ) : (
                editorRef.current && editorLoaded ? (
                    <>
                        <editorRef.current.CKEditor
                            name={name}
                            onReady={(editor) => {
                                console.log('Editor is ready to use!', editor);
                                editor.ui.getEditableElement().parentElement.insertBefore(editor.ui.view.toolbar.element, editor.ui.getEditableElement());
                            }}
                            editor={editorRef.current.DecoupledEditor}
                            // config={{
                            //     // ... your CKEditor configuration options
                            // }}
                            config={{
                                extraPlugins: [uploadPlugin],
                                link: {
                                    addTargetToExternalLinks: true, // Automatically set target="_blank" for external links
                                },
                                toolbar: {
                                    items: [
                                        'undo', 'redo',
                                        '|', 'heading',
                                        '|', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor',
                                        '|', 'bold', 'italic', 'strikethrough', 'code',
                                        '|', 'link', 'insertImage', 'mediaEmbed', 'insertTable', 'blockQuote', 'codeBlock',
                                        '|', 'alignment',
                                        '|', 'bulletedList', 'numberedList', 'outdent', 'indent'
                                    ],
                                    shouldNotGroupWhenFull: true
                                },
                                // Your CKEditor configuration options
                                heading: {
                                    options: [
                                        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                                        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                                        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                                        { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                                        { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                                        { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                                        { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
                                        // Add more heading levels as needed
                                    ],
                                },
                                image: {
                                    toolbar: [
                                        'imageStyle:alignLeft', // Add left alignment option
                                        'imageStyle:alignCenter',
                                        'imageStyle:alignRight',
                                        '|',
                                        'toggleImageCaption',
                                        'imageTextAlternative',
                                        '|',
                                        'linkImage',
                                        'resizeImage',
                                        // 'resizeImage:50',
                                        // 'resizeImage:75',
                                        // 'resizeImage:original',
                                    ],
                                    styles: [
                                        'alignLeft', 'alignCenter', 'alignRight' // Image alignment styles
                                    ],
                                    align: ['left', 'center', 'right'],
                                    // resizeOptions: [
                                    //   // {
                                    //   //   name: 'resizeImage:original',
                                    //   //   label: 'Original',
                                    //   //   value: null
                                    //   // },
                                    //   // {
                                    //   //   name: 'resizeImage:50',
                                    //   //   label: '50%',
                                    //   //   value: '50',
                                    //   //   icon: 'resize-small',
                                    //   // },
                                    //   // {
                                    //   //   name: 'resizeImage:75',
                                    //   //   label: '75%',
                                    //   //   value: '75'
                                    //   // }
                                    //   // You can add more resize options as needed
                                    // ],
                                    insert: {
                                        // This is the default configuration, you do not need to provide
                                        // this configuration key if the list content and order reflects your needs.
                                        integrations: ['upload', 'url'],
                                        type: 'auto'
                                    }
                                },
                                simpleUpload: {
                                    // The URL that the images are uploaded to.
                                    uploadUrl: 'http://example.com',

                                    // Enable the XMLHttpRequest.withCredentials property.
                                    withCredentials: true,

                                    // Headers sent along with the XMLHttpRequest to the upload server.
                                    headers: {
                                        'X-CSRF-TOKEN': 'CSRF-Token',
                                        Authorization: 'Bearer <JSON Web Token>'
                                    }
                                },
                                ckfinder: {
                                    // Upload the images to the server using the CKFinder QuickUpload command
                                    // You have to change this address to your server that has the ckfinder php connector
                                    uploadUrl: "" //Enter your upload url
                                },
                                mediaEmbed: {
                                    previewsInData: true,
                                    // previewsInData: false,
                                    providers: [
                                        {
                                            //     name: "youtube",
                                            //     url: /^https?:\/\/(?:www\.)?youtube\.com\/watch\?v=(.+)/,
                                            //     html: (match) => {
                                            //         return `<iframe width="560" height="315" src="https://www.youtube.com/embed/${match[1]}" frameborder="0" allowfullscreen></iframe>`;
                                            //     }
                                            // }
                                            // Add more media providers as needed

                                            // hint: this is just for previews. Get actual HTML codes by making API calls from your CMS
                                            name: 'iframely previews',

                                            // Match all URLs or just the ones you need:
                                            url: /.+/,

                                            html: match => {
                                                const url = match[0];

                                                var iframeUrl = IFRAME_SRC + '?app=1&api_key=' + API_KEY + '&url=' + encodeURIComponent(url);
                                                // alternatively, use &key= instead of &api_key with the MD5 hash of your api_key
                                                // more about it: https://iframely.com/docs/allow-origins

                                                return (
                                                    // If you need, set maxwidth and other styles for 'iframely-embed' class - it's yours to customize
                                                    '<div class="iframely-embed">' +
                                                    '<div class="iframely-responsive">' +
                                                    `<iframe src="${iframeUrl}" ` +
                                                    'frameborder="0" allow="autoplay; encrypted-media" allowfullscreen>' +
                                                    '</iframe>' +
                                                    '</div>' +
                                                    '</div>'
                                                );
                                            }
                                        }
                                    ]
                                }
                            }}
                            data={value}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                onChange(data);
                            }}
                        />
                    </>
                ) : (
                    <div>Editor not loaded</div>
                )
            )}
        </div>
    );

}

export default Editor;