import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { userLogin } from "../../../store/Action/usersAction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "./login.css";

export default function LogIn() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const [token, setToken] = useState(null);
  // const [tokenTimer, setTokenTimer] = useState(null);
  // const [userId, setUserId] = useState(null);
  // const [role, setRole] = useState(null);

  const dispatch = useDispatch();

  // const setAuthTimer = (duration) => {
  //   // console.log(duration, " : Set Auth Timer");
  //   // let timeDuration = setTimeout(() => {
  //   //   logout()
  //   // }, duration * 1000);
  //   // // console.log(timeDuration, " : timeDuration");
  //   // setTokenTimer(timeDuration);
  //   // console.log(tokenTimer, " : TokeTimer");
  // }

  // const logout = () => {
  //   setToken(null);
  //   setIsAuthenticated(false);
  //   setUserId(null);
  //   setRole(null);
  //   clearTimeout(tokenTimer);
  //   clearAuthData();
  //   navigate('/');
  // }

  // const clearAuthData = () => {
  //   localStorage.removeItem("token");
  //   localStorage.removeItem("expiration");
  //   localStorage.removeItem("userId");
  //   localStorage.removeItem("role");
  // }

  const saveAuthData = (
    token,
    expirationDate,
    userId,
    role
  ) => {
    localStorage.setItem("token", token);
    localStorage.setItem("expiration", expirationDate.toISOString());
    localStorage.setItem("userId", userId);
    localStorage.setItem("role", role);
  }

  // useEffect(() => {
  //   // Remove the overlay class when the component unmounts
  //   return () => {
  //     document.body.classList.add('overlay');
  //   };
  // }, [])

  const handleSubmit = async (event) => {
    event.preventDefault();
    let payload = {
      email: email,
      password: password
    }
    // console.log(payload, " : Payload");
    if (email && password) {
      const response = await dispatch(userLogin(payload));
      // console.log(response, " : Auth Response");
      // setToken(response.token);
      if (response.token) {
        let expiresInDuration = response.expiresIn;
        // setAuthTimer(expiresInDuration);
        // setIsAuthenticated(true);
        // setUserId(response.userId);
        // setRole(response.fetchedUser.role);
        const now = new Date();
        const expirationDate = new Date(
          now.getTime() + expiresInDuration * 1000
        );
        saveAuthData(
          response.token,
          expirationDate,
          response.userId,
          response.fetchedUser.role
        );
        navigate('/');
      }

    } else {
      toast.error("Please provide credentials!");
    }
  }

  return (
    <div className="container">
      {/* <div className="login-container"> */}
      <div>
        <div>
          <div className="line"></div>
          <div className="card">
            <div className="card-header">Log In</div>
            <div className="card-body">
              <form>
                <div className="form-group">
                  <label htmlFor="exampleText1">Email Address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleText1"
                    aria-describedby="emailHelp"
                    placeholder="Email Address"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="exampleText1">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleText1"
                    aria-describedby="emailHelp"
                    placeholder="Password"
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </div>
                <button type="button" className="btn btn-danger btn-lg" onClick={handleSubmit}>
                  Log In
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
